import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RiCloseCircleFill } from "react-icons/ri";
import { AiOutlineCopy } from "react-icons/ai";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import AddTaskForm from "./AddTaskForm";
import ButtonUI from "../material-ui/ButtonUI";
import addTask from "../../api/addTask";
import { AuthContext } from "../../context/Auth/AuthContext";
import addDoc from "../../images/add-document.png";
import getAllTaskTypeList from "../../api/getAllTaskTypeList";
import getAllCaseDetails from "../../api/getAllCaseDetails";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Tooltip from "@mui/material/Tooltip";
import getAllSugarUsers from "../../api/getAllSugarUsers";
import { format } from "date-fns";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<RiCloseCircleFill />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

const addTaskFormSchema = yup.object().shape({
	taskSubject: yup.string().required("Task Subject is required field"),
	dueDate: yup.string().required("Due Date is required field"),
	type: yup.string().required("Type is required field"),
	priority: yup.string().required("Priority is required field"),
	assignedUser: yup.string().required("Assigned Name is required field"),
});

/*** Component for adding tasks ***/
const AddTask = ({ contactId }) => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [userLoading, setUserLoading] = useState(false);
	const { token, userID } = useContext(AuthContext);
	const [typeOptions, setTypeOptions] = useState([]);
	const [userList, setUserList] = useState("");
	const [caseId, setCaseId] = useState("");
	const [caseType, setCaseType] = useState("");
	const [caseImmType, setCaseImmType] = useState("");
	const [caseStatus, setCaseStatus] = useState("");
	const [caseSubtype, setCaseSubtype] = useState("");
	const [caseAssignedUserId, setCaseAssignedUserId] = useState("");

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(addTaskFormSchema),
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCopy = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				toast.success("Copied to clipboard!", {
					position: "top-center",
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch((err) => {
				toast.error("Failed to copy!", {
					position: "top-center",
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
	};

	const fetchTypes = async () => {
		try {
			const response = await getAllTaskTypeList({
				token,
			});
			setTypeOptions(response);
		} catch (error) {
			return error;
		}
	};

	const getCaseDetails = async () => {
		try {
			const caseParams = {
				caseId: contactId,
			};

			const response = await getAllCaseDetails({
				caseParams: caseParams,
			});
			setCaseId(response?.caseDetails?.id);
			setCaseType(response?.caseDetails?.type);
			setCaseStatus(response?.caseDetails?.status);
			setCaseSubtype(response?.caseDetails?.subtype);
			setCaseAssignedUserId(response?.caseDetails?.assigned_user_id);
			setCaseImmType(response?.caseDetails?.im_case_type_c);
		} catch (error) {
			return error;
		}
	};

	const fetchUsers = async () => {
		setUserLoading(true);
		try {
			const response = await getAllSugarUsers({
				token,
			});
			setUserList(response?.user);
			setUserLoading(false);
		} catch (error) {
			setUserLoading(false);
			return error;
		}
	};

	useEffect(() => {
		fetchTypes();
		getCaseDetails();
		fetchUsers();
	}, []);

	const onAddTaskFormSubmit = async (addTaskFormData) => {
		setLoading(true);

		const payloadParams = {
			caseId: contactId,
			subject: addTaskFormData.taskSubject,
			type: addTaskFormData.type,
			priority: addTaskFormData.priority,
			description: addTaskFormData.taskDesc,
			date_due: format(
				new Date(addTaskFormData.dueDate),
				"yyyy-MM-dd HH:mm:ss"
			),
			assigned_user_id: addTaskFormData.assignedUser,
		};

		const addTaskFormResponse = await addTask({
			token: token,
			payloadParams: payloadParams,
		});

		if (addTaskFormResponse.code === 200) {
			setOpen(false);
			reset(); // Reset form fields
			setLoading(false);
			toast.success("Task is added successfully", {
				position: "top-center",
				autoClose: 3000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else {
			setLoading(false);
			toast.error("Error while adding task", {
				position: "top-center",
				autoClose: 5000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<div className="dialog-modal">
			<ButtonUI
				formButton={false}
				variant="outlined"
				buttonText="Add Task"
				style={{
					backgroundColor: "#3792ed",
					color: " white",
					marginRight: "5px",
					minWidth: "fit-content",
					border: "none",
					height: "100%",
				}}
				onClickFunc={handleClickOpen}
			/>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				className="custom-edit-modal"
				open={open}
			>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					className=""
					onClose={handleClose}
				>
					<div className="add-document-title-with-img">
						<div className="relative">
							<img src={addDoc} height="100px" width="120px" alt="Add Task" />
							<span className="add-document-title text-[16px] absolute bottom-[-34px] left-[30px]">
								Add Task
							</span>
						</div>
						<div className="">
							<fieldset className=" border border-2 border-gray-600 rounded-md p-[15px]">
								<legend className="w-max text-[#007bff] text-[18px] font-semibold">
									Case Details
								</legend>
								<div className="all-case-details-columns grid grid-cols-2 gap-3">
									<div className="flex items-center ">
										<Tooltip title={caseId}>
											<span className="truncate text-sm">
												<strong>Case ID:</strong> {caseId}
											</span>
										</Tooltip>
									</div>
									<div className="flex items-center">
										<Tooltip title={caseStatus}>
											<span className="truncate text-sm">
												<strong>Status:</strong> {caseStatus}
											</span>
										</Tooltip>
									</div>
									<div className="flex items-center">
										<Tooltip title={caseType}>
											<span className="truncate text-sm">
												<strong>Type:</strong> {caseType}
											</span>
										</Tooltip>
									</div>
									<div className="flex items-center">
										<Tooltip title={caseImmType}>
											<span className="truncate text-sm">
												<strong>Case Type:</strong> {caseImmType}
											</span>
										</Tooltip>
									</div>
									<div className="flex items-center">
										<Tooltip title={caseSubtype}>
											<span className="truncate text-sm">
												<strong>Sub Type:</strong> {caseSubtype}
											</span>
										</Tooltip>
									</div>
									<div className="flex items-center">
										<Tooltip title={caseAssignedUserId}>
											<span className="truncate text-sm">
												<strong>Assigned To:</strong> {caseAssignedUserId}
											</span>
										</Tooltip>
									</div>
								</div>
							</fieldset>
						</div>
					</div>
				</BootstrapDialogTitle>
				{!userLoading ? (
					<DialogContent dividers>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<AddTaskForm
								register={register}
								control={control}
								errors={errors}
								typeOptions={typeOptions}
								userList={userList}
							/>
						</LocalizationProvider>
					</DialogContent>
				) : (
					""
				)}
				<DialogActions sx={{ paddingBottom: "15px !important" }}>
					<div className="popup_submit_btn">
						<ButtonUI
							loading={loading}
							variant="contained"
							buttonText="Submit"
							style={{
								backgroundColor: "#007bff",
								boxShadow: "none",
								border: "1px solid #007bff",
								fontWeight: "500",
								borderRadius: "4px",
								letterSpacing: "0.07rem",
								marginTop: "20px",
							}}
							handleSubmit={handleSubmit}
							onFormSubmit={onAddTaskFormSubmit}
						/>
					</div>
				</DialogActions>
			</BootstrapDialog>
		</div>
	);
};

export default AddTask;
