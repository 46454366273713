import React, { useState, useEffect, useContext, useCallback } from "react";
import { useDebounce } from "use-debounce";
import { v4 as uuidv4 } from "uuid";
import pdfDetails from "../../api/pdfDetails";
import { AuthContext } from "../../context/Auth/AuthContext";
import Loading from "../material-ui/Loading";
import PdfDetails from "./PdfDetails";
import PdfVersion from "./PdfVersion";
import PdfEditor from "./PdfEditor";
import pdfBase64Data from "../../api/pdfBase64Data";
import getDocumentRequestStatus from "../../api/getDocumentRequestStatus";
import { TbEdit } from "react-icons/tb";
import { IoArrowBackCircleSharp, IoCloseSharp } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { BiDetail } from "react-icons/bi";
import { TbCircleCheck } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import ApprovePDFDocumentPopup from "../client-table/client-documents/ApprovePDFDocumentPopup";
import RejectPDFDocumentPopup from "../client-table/client-documents/RejectPDFDocumentPopup";
import AddTask from "../document-table/AddTask";
import { TbVersions } from "react-icons/tb";
import { FaHistory } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import { Tooltip } from "@mui/material";
import ButtonUI from "../material-ui/ButtonUI";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputUI from "../material-ui/InputUI";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import SelectBox from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import getClientRefNo from "../../api/getClientRefNo";
import getClientName from "../../api/getClientName";
import { toast } from "react-toastify";
import editDocument from "../../api/editDocument";
import baseUrl from "../../config/baseUrl";
import clientsData from "../../api/clients";
import editClient from "../../api/editClient";
import getAllFieldTypeList from "../../api/getAllFieldTypeList";
import { BiLinkAlt } from "react-icons/bi";
import LinkToRequest from "../document-table/LinkToRequest";
import useDebounceSearch from "../../hooks/useDebounceSearch";
import "../../styles/PdfViewer.css";
import elasticToken from "../../config/elasticToken";
import SendSMS from "./SendSMS";

const updateDocumentDetailsFormSchema = yup.object().shape({
  name: yup.string().required("Document Name is required field"),
  subcategory: yup
    .string()
    .nullable()
    .required("Sub Category is required field"),
});

const PdfViewer = ({
  documentType,
  clientID,
  documentID,
  documentTitle,
  documentURL,
  changeActiveTab,
  document_table,
  document_version,
  documentRequestID,
  documentCategory,
  documentDesc,
  currentTabId,
  closeTabById,
  setDocumentDetails,
  fromTable,
  setUpdatedFromPDF,
  linkedDocReqName,
  documentSubCategory,
  clientPhone,
}) => {
  const [isPdfDetailsContainerOpen, setIsPdfDetailsContainerOpen] =
    useState(false);
  const [pdfData, setPdfData] = useState({});
  const [editor, setEditor] = useState(false);
  const [pdfBase64, setPdfBase64] = useState("");
  const [documentVersionId, setDocumentVersionId] = useState(null);
  const [pdfSrc, setPdfSrc] = useState("");
  const [documentRequestStatus, setDocumentRequestStatus] = useState("");
  const [approveConfirmOpen, setApproveConfirmOpen] = useState(false);
  const [disapproveConfirmOpen, setDisapproveConfirmOpen] = useState(false);

  const [clientNames, setClientNames] = useState([]);
  const [description, setDescription] = useState(documentDesc);
  const [subCategoryOption, setSubCategoryOption] = useState([]);
  const [contactId, setContactId] = useState(clientID);
  const [linkRequest, setLinkRequest] = useState(false);
  const [linkRequestOpen, setLinkRequestOpen] = useState(false);
  const [requestOptions, setRequestOptions] = useState([]);
  const [requestId, setRequestId] = useState(documentRequestID);
  const [showLinkButton, setShowLinkButton] = useState(false);
  const [assignClient, setAssignClient] = useState(false);
  const [clientNameLabel, setClientNameLabel] = useState("");
  const [selectedRequestLabel, setSelectedRequestLabel] = useState("");
  const [searchedTerm, setSearchedTerm] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [showMoreLoading, setShowMoreLoading] = useState(false);

  // const [debouncedSearchTerm, setDebouncedSearchTerm] = useDebounce('', 500);

  // const handleClientNameSearch = useCallback((newSearchTerm) => {
  //   setDebouncedSearchTerm(newSearchTerm);
  // }, []);

  // useEffect(() => {
  //   if (debouncedSearchTerm) {
  //     getClientList(debouncedSearchTerm);
  //   }
  // }, [debouncedSearchTerm]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateDocumentDetailsFormSchema),
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.fields_name,
  });

  const [pdfTab, setPdfTab] = useState([
    {
      id: uuidv4(),
      title: <IoDocumentText />,
      content: (pdfData) => (
        <PdfDetails
          pdfData={pdfData}
          documentID={documentID}
          documentTitle={documentTitle}
          documentType={documentType}
          pdfDetailsVersionData={pdfDetailsVersionData}
          documentVersionId={documentVersionId}
          setDocumentVersionId={setDocumentVersionId}
          document_version={document_version}
        />
      ),
    },
    {
      id: uuidv4(),
      title: <TbVersions />,
      content: (documentID) => <PdfVersion documentID={documentID} />,
    },
  ]);
  const [tabValue, setTabValue] = useState(pdfTab[0].id);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pdfDataLoading, setPdfDataLoading] = useState(true);
  const [searchClientNotFound, setSearchClientNotFound] = useState(false);

  const { token, user_id, is_admin } = useContext(AuthContext);

  const getDocumentRequestStatusFunc = async () => {
    const response = await getDocumentRequestStatus(token, requestId);
    const data = await response.data;
    setDocumentRequestStatus(data[0]?.document_status);
  };

  const pdfDetailsData = async () => {
    const response = await pdfDetails(token, documentID, document_table);
    const data = await response.data;
    setLoading(false);
    setPdfDataLoading(false);

    if (response.code == 200) {
      setPdfData(data);
      setPdfSrc(data[0]?.pdflink);
    } else {
      toast.error("Error while getting document", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const pdfDetailsVersionData = async () => {
    const response = await pdfDetails(
      token,
      documentID,
      document_table,
      documentVersionId
    );
    const data = await response.data;
    setPdfData(data);
    setLoading(false);
    setPdfDataLoading(false);
    setPdfSrc(data[0]?.pdflink);
  };

  const pdfBlob = async () => {
    const response = await pdfBase64Data(token, documentID, document_table);
    const data = await response.data;
    setPdfBase64(data);
  };

  const getDocumentDetails = async () => {
    if (setDocumentDetails) {
      const url = `${baseUrl}/api/get-all-doc-data?document_id=${documentID}&isAdmin=${is_admin}&userId=${user_id}`;
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        setDocumentDetails(data?.data[0]?.document[0]);
        if (setUpdatedFromPDF) {
          setUpdatedFromPDF(true);
        }
      } catch (error) {
        return error;
      }
    }
  };

  useEffect(() => {
    if (requestId) {
      getDocumentRequestStatusFunc();
    }
  }, [requestId]);

  useEffect(() => {
    if (linkRequest) {
      getDocumentDetails();
    }
  }, [linkRequest]);

  useEffect(() => {
    setValue("addClientName", clientID);
    setValue("name", documentTitle);
    setValue("subcategory", documentSubCategory);
    fetchSubCategory();
    //getClientList();
    if (clientID && clientID !== "null") {
      getClientNameDetails();
      getClientRefNumber();
      fetchRequestList(clientID);
    }
  }, []);

  useEffect(() => {
    if (assignClient === true) {
      fetchRequestList(contactId);
    }
  }, [assignClient]);

  useEffect(() => {
    pdfDetailsData();
    pdfBlob();
  }, []);

  useEffect(() => {
    // console.log(clientID + "  contactId " + contactId + "  requestId " + requestId + " linkRequest  " + linkRequest + " requestOptions " + requestOptions + "  documentRequestStatus " + documentRequestStatus);
    (clientID || contactId) &&
    !requestId &&
    !linkRequest &&
    // requestOptions?.length > 0 &&
    !documentRequestStatus
      ? setShowLinkButton(true)
      : setShowLinkButton(false);
  }, [requestOptions, documentRequestStatus]);

  const handleChange = (index) => {
    setTabValue(index);
  };

  const handleApproveDocument = (e) => {
    setApproveConfirmOpen(true);
  };

  const handleDisapproveDocument = (e) => {
    setDisapproveConfirmOpen(true);
  };

  const changePdfDetailsContainerVisibility = () => {
    setIsPdfDetailsContainerOpen(!isPdfDetailsContainerOpen);
  };

  const getClientRefNumber = async () => {
    const clientRefNoResponse = await getClientRefNo({
      contact_id: clientID,
      token: token,
    });
    setValue("caseId", clientRefNoResponse?.data[0]?.client_reference_number);
  };

  const getClientNameDetails = async () => {
    const clientNameResponse = await getClientName({
      contact_id: clientID,
      token: token,
    });
    setClientNameLabel(clientNameResponse?.data[0]?.name);
  };

  const fetchSubCategory = async () => {
    try {
      const response = await getAllFieldTypeList({
        token,
        type: "document_subtype",
      });
      setSubCategoryOption(response.data);
    } catch (error) {
      return error;
    }
  };

  const handleClientNameChange = async (contactId) => {
    const clientRefNoResponse = await getClientRefNo({
      contact_id: contactId,
      token: token,
    });

    if (clientRefNoResponse.code === 200) {
      setClientNameLabel("Client Name");
      setValue("caseId", clientRefNoResponse?.data[0]?.client_reference_number);
      setContactId(contactId);
    } else {
      toast.error(
        "Error while getting client details in DMS. Please contact admin!",
        {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setValue("caseId", "");
      setContactId("");
    }
  };
  const fetchRequestList = async (selectedClient) => {
    if (selectedClient) {
      setLinkRequest(false);
      //const url = `${baseUrl}/api/get-doc-request-data?isAdmin=${is_admin}&userId=${user_id}&contact_id=${selectedClient}&unlinked=True`;
      const url = `${baseUrl}/api/get-client-document-requests-for-linking?userId=1&isAdmin=1&contact_id=${selectedClient}`;
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        if (data?.code === 200) {
          // Create the additional item
          const additionalItem = {
            document_request_id: "Additional Document",
            document_req_name: "Additional Document",
          };

          // Add it as the first item to the data array
          const updatedData = [
            additionalItem,
            ...data?.data?.document_requests_for_linking,
          ];

          // Set the updated data to the state
          setRequestOptions(updatedData);
        } else {
          // Set only the additional document in the state
          setRequestOptions([
            {
              document_request_id: "Additional Document",
              document_req_name: "Additional Document",
            },
          ]);
        }
      } catch (error) {
        return error;
      }
    }
  };

  const handleLinkRequest = (e) => {
    setLinkRequestOpen(true);
  };

  const onEditDocumentFormSubmit = async (editDocumentFormData) => {
    setSubmitLoading(true);
    // await editClient({
    //   contact_id: contactId,
    //   token: token,
    //   user_id: user_id,
    //   client_reference_number: editDocumentFormData.caseId,
    // });

    const editDocumentFormResponse = await editDocument({
      token: token,
      user_id: user_id,
      document_id: documentID,
      document_desc: description,
      document_name: editDocumentFormData.name,
      document_sub_category: editDocumentFormData.subcategory,
      contact_id: editDocumentFormData.addClientName,
    });

    if (editDocumentFormResponse.code === 200) {
      // const urlAddDocumentRequests = `${baseUrl}/api/add-document-requests?contact_id=${editDocumentFormData.addClientName}`;
      // try {
      //   const responseAddDocumentRequests = await fetch(
      //     urlAddDocumentRequests,
      //     {
      //       method: "POST",
      //       headers: {
      //         Accept: "application/json",
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   );

      //   await responseAddDocumentRequests.json();
      // } catch (error) {
      //   return error;
      // }

      // const url = `${baseUrl}/api/get-all-doc-data?document_id=${documentID}&isAdmin=${is_admin}&userId=${user_id}`;
      // try {
      //   const response = await fetch(url, {
      //     method: "GET",
      //     headers: {
      //       Accept: "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   });

      //   const data = await response.json();
      //   if (data.code === 200) {
      //     if (setDocumentDetails) {
      //       setDocumentDetails(data?.data[0]?.document[0]);
      //     }
      //     if (setUpdatedFromPDF) {
      //       setUpdatedFromPDF(true);
      //     }
      //     setAssignClient(true);
      //   }
      // } catch (error) {
      //   return error;
      // }
      getDocumentDetails();
      setAssignClient(true);
      setSubmitLoading(false);
      toast.success("Document is updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setSubmitLoading(false);
      toast.error("Error while updating document", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getClientList = useCallback(
    async (searchTerm, offsetValue, contactId) => {
      if (offsetValue === 0) {
        setClientNames([]);
      }
      if (searchTerm) {
        setClientNameLabel("Client Name");
        setSearchLoading(true);
        try {
          const url = `https://clguat.nablasol.net/rest/v11_20/customGlobalSearch?q=${searchTerm}&max_num=20&offset=${offsetValue}&highlights=true&module_list=Cases`;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${elasticToken}`,
            },
          });
          const data = await response.json();

          if (data?.total > 0) {
            setClientNames((prev) => [...prev, ...data.records]);
            setTotal(data.total);
            setSearchLoading(false);
            setShowMoreLoading(false);
          } else {
            setSearchClientNotFound(true);
            setSearchLoading(false);
          }
        } catch (error) {
          setSearchLoading(false);
          console.error("Error:", error);
        }
      } else {
        if (!contactId) {
          setClientNameLabel("Client Name / Alien Number");
        }
      }
    },
    [is_admin, user_id, token]
  );

  const [searchTerm, handleClientNameSearch] = useDebounceSearch(
    "",
    documentID,
    offset,
    clientID,
    2000,
    getClientList
  );

  useEffect(() => {
    setOffset(0);
    setTotal(0);
  }, [searchedTerm]);

  return (
    <>
      {approveConfirmOpen && (
        <ApprovePDFDocumentPopup
          documentRequestId={requestId}
          setReqStatus={setDocumentRequestStatus}
          setApproveConfirmOpen={setApproveConfirmOpen}
          {...(fromTable !== "Documents" && {
            currentTabId: currentTabId,
            closeTabById: closeTabById,
          })}
        />
      )}

      {disapproveConfirmOpen && (
        <RejectPDFDocumentPopup
          documentRequestId={requestId}
          setReqStatus={setDocumentRequestStatus}
          setDisapproveConfirmOpen={setDisapproveConfirmOpen}
          currentTabId={currentTabId}
          closeTabById={closeTabById}
        />
      )}

      {linkRequestOpen && (
        <LinkToRequest
          documentId={documentID}
          requestOptions={requestOptions}
          setLinkRequest={setLinkRequest}
          setReqStatus={setDocumentRequestStatus}
          setRequestId={setRequestId}
          setLinkRequestOpen={setLinkRequestOpen}
          setSelectedRequestLabel={setSelectedRequestLabel}
          // currentTabId={currentTabId}
          // closeTabById={closeTabById}
        />
      )}

      {!loading ? (
        <div className="pdf-viewer-container">
          {!editor ? (
            <div className="pdf-viewer">
              <div className="pdf-viewer-header">
                <div className="pdf-document-title capitalize">
                  {documentTitle}
                  {linkedDocReqName
                    ? " - " + linkedDocReqName + " (Linked Request)"
                    : null}
                </div>
                <div className="view-pdf-doc-icon">
                  {clientID && <AddTask contactId={contactId} />}
                  {clientID && clientPhone ? (
                    <SendSMS clientPhone={clientPhone} clientID={clientID} />
                  ) : null}
                  {
                    // documentType === "pdf" &&
                    document_table != "contract_requests" &&
                      (document_table != "document_request_settings" ||
                        !document_table) && (
                        <>
                          {(clientID || contactId) &&
                            requestId &&
                            documentRequestStatus?.toLowerCase() ===
                              "approved" && (
                              <div
                                className="all-document-pdf-viewer-icon !cursor-default"
                                style={{ color: "green", fontSize: 26 }}
                                title="Approved"
                              >
                                <TbCircleCheck />
                              </div>
                            )}
                          {(clientID || contactId) &&
                            requestId &&
                            documentRequestStatus?.toLowerCase() ===
                              "rejected" && (
                              <div
                                className="all-document-pdf-viewer-icon !cursor-default"
                                style={{ color: "red", fontSize: 29 }}
                                title="Rejected"
                              >
                                <TiDeleteOutline />
                              </div>
                            )}

                          <ButtonUI
                            formButton={false}
                            variant="outlined"
                            buttonText={<TbCircleCheck />}
                            style={{
                              backgroundColor:
                                documentRequestStatus?.toLowerCase() ===
                                "uploaded"
                                  ? "#28a745"
                                  : "#d8d8e4df",

                              color:
                                documentRequestStatus?.toLowerCase() ===
                                "uploaded"
                                  ? "white"
                                  : "rgb(18 215 24)",
                              marginRight: "5px",
                              marginBottom: "5px",
                              padding: "8px",
                              minWidth: "fit-content",
                              border: "none",
                            }}
                            disabled={
                              documentRequestStatus?.toLowerCase() ===
                              "uploaded"
                                ? false
                                : true
                            }
                            onClickFunc={handleApproveDocument}
                          />
                          <ButtonUI
                            formButton={false}
                            variant="outlined"
                            buttonText={<TiDeleteOutline />}
                            style={{
                              backgroundColor:
                                documentRequestStatus?.toLowerCase() ===
                                "uploaded"
                                  ? "#dc3545"
                                  : "#d8d8e4df",

                              color:
                                documentRequestStatus?.toLowerCase() ===
                                "uploaded"
                                  ? "white"
                                  : "rgb(215 18 18)",
                              marginRight: "5px",
                              marginBottom: "5px",
                              padding: "8px",
                              minWidth: "fit-content",
                              border: "none",
                            }}
                            disabled={
                              documentRequestStatus?.toLowerCase() ===
                              "uploaded"
                                ? false
                                : true
                            }
                            onClickFunc={handleDisapproveDocument}
                          />
                          <ButtonUI
                            formButton={false}
                            variant="outlined"
                            buttonText={<BiLinkAlt />}
                            style={{
                              backgroundColor: showLinkButton
                                ? "#17a2b8"
                                : "#d8d8e4df",
                              color: showLinkButton
                                ? "white"
                                : "rgb(18 215 24)",
                              marginRight: "5px",
                              marginBottom: "5px",
                              padding: "8px",
                              minWidth: "fit-content",
                              border: "none",
                            }}
                            disabled={showLinkButton ? false : true}
                            onClickFunc={handleLinkRequest}
                          />
                        </>
                      )
                  }

                  {documentType === "pdf" &&
                  document_table != "contract_requests" &&
                  (document_table != "document_request_settings" ||
                    !document_table) ? (
                    <div
                      onClick={() => setEditor(!editor)}
                      className="edit-doc-btn"
                      title="Edit PDF"
                    >
                      <TbEdit />
                    </div>
                  ) : null}
                  <div
                    className="pdf-viewer-icon"
                    onClick={changePdfDetailsContainerVisibility}
                    title="PDF Details"
                  >
                    {isPdfDetailsContainerOpen ? (
                      <IoCloseSharp />
                    ) : (
                      <BiDetail />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  document_table != "contract_requests" &&
                  document_table != "document_request_settings"
                    ? "document-viewer-with-from"
                    : null
                }
              >
                <div className="iframe-wrapper">
                  {pdfData ? (
                    documentType === "pdf" && pdfSrc ? (
                      <iframe
                        title={documentTitle}
                        src={pdfSrc}
                        width="100%"
                        height="590"
                        key={pdfSrc}
                      ></iframe>
                    ) : (
                      <img
                        src={pdfData[0]?.pdflink}
                        width="100%"
                        height="590"
                      />
                    )
                  ) : null}
                </div>
                {document_table != "contract_requests" &&
                  document_table != "document_request_settings" && (
                    <div className="document-viewer-form">
                      <div className="field_wrapper">
                        <div className="field">
                          <Controller
                            control={control}
                            name="addClientName"
                            render={({
                              field: { onChange, value, ...field },
                            }) => (
                              <Autocomplete
                                className="clientNameAuto"
                                name="addClientName"
                                noOptionsText={
                                  !searchClientNotFound
                                    ? "Please enter the client name / alien number"
                                    : "No clients found"
                                }
                                options={[
                                  ...clientNames,
                                  ...(total > offset + 20 || showMoreLoading
                                    ? [{ name: "Show More", id: "show_more" }]
                                    : []),
                                ]}
                                loading={searchLoading}
                                // getOptionLabel={(option) => option.name}
                                getOptionLabel={(option) => {
                                  // Check if option is an object and has a valid 'name' property
                                  if (
                                    typeof option === "object" &&
                                    option !== null &&
                                    typeof option.name === "string"
                                  ) {
                                    if (option.id === "show_more")
                                      return option.name;
                                    return option.name || "";
                                  }

                                  // Return a fallback value (could be an empty string or some placeholder text)
                                  return "";
                                }}
                                clearOnBlur={false}
                                disabled={
                                  clientID && clientID !== "null" ? true : false
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={
                                      !clientNameLabel
                                        ? "Client Name / Alien Number"
                                        : clientNameLabel
                                    }
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                    onChange={(event) => {
                                      if (!event.target.value) {
                                        setClientNameLabel(
                                          "Client Name / Alien Number"
                                        );
                                      }
                                      const searchTerm = event.target.value;
                                      handleClientNameSearch(searchTerm);
                                      // Store selectedClientName in component state or prop
                                      setSearchedTerm(searchTerm);
                                    }}
                                    onBlur={() => {
                                      if (!contactId) {
                                        setClientNameLabel(
                                          "Client Name / Alien Number"
                                        );
                                      }
                                    }}
                                    disabled={clientID && clientID !== "null"}
                                  />
                                )}
                                filterOptions={(options, state) => options}
                                renderOption={(props, option) => {
                                  if (option.id === "show_more") {
                                    return (
                                      <li
                                        {...props}
                                        onClick={async () => {
                                          setShowMoreLoading(true); // Set loading to true
                                          setOffset((prev) => prev + 20);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          color: showMoreLoading
                                            ? "grey"
                                            : "#007bff",
                                        }}
                                      >
                                        {showMoreLoading
                                          ? "Loading..."
                                          : option.name}
                                      </li>
                                    );
                                  }
                                  return (
                                    <li {...props} key={option.id}>
                                      {option.name}
                                      <br />
                                      Alien Number: {option.im_alien_number_c}
                                    </li>
                                  );
                                }}
                                {...field}
                                value={
                                  clientNames?.find(
                                    (clientName) => clientName.id === value
                                  ) || null
                                }
                                onChange={(event, newValue) => {
                                  if (
                                    newValue === null ||
                                    newValue === undefined
                                  ) {
                                    onChange(null);
                                    setValue("caseId", "");
                                    setContactId("");
                                  } else if (newValue.id !== "show_more") {
                                    const selectedClient = clientNames?.find(
                                      (clientName) =>
                                        clientName.name === newValue?.name
                                    );
                                    if (selectedClient) {
                                      onChange(selectedClient.id);
                                      handleClientNameChange(selectedClient.id);
                                    }
                                  }
                                }}
                                classes={{ paper: "autocomplete-paper" }}
                              />
                            )}
                          />
                          <span className="error-message">
                            {errors["addClientName"]?.message}
                          </span>
                        </div>
                      </div>

                      <div className="field_wrapper">
                        <div className="field">
                          <Controller
                            name="caseId"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <InputUI
                                name="caseId"
                                label="Client Reference Number"
                                disabled
                                type="text"
                                value={value ? value : ""}
                                setValue={onChange}
                                variant="outlined"
                                register={register}
                                cssStyles={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="field_wrapper">
                        <div className="field">
                          <Controller
                            name="name"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <InputUI
                                name="name"
                                label="Document Name"
                                type="text"
                                value={value ? value : ""}
                                setValue={onChange}
                                variant="outlined"
                                register={register}
                                cssStyles={{
                                  margin: "20px 0px",
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                          <span className="error-message">
                            {errors["name"]?.message}
                          </span>
                        </div>
                      </div>
                      <div className="field_wrapper">
                        <div className="field">
                          <Controller
                            name="category"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <InputUI
                                name="category"
                                label="Category"
                                disabled
                                type="text"
                                value={documentCategory ? documentCategory : ""}
                                variant="outlined"
                                register={register}
                                cssStyles={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div
                        className="field_wrapper"
                        style={{ marginTop: "15px", marginBottom: "5px" }}
                      >
                        <FormControl className="" fullWidth>
                          <Controller
                            control={control}
                            name="subcategory"
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                options={subCategoryOption}
                                filterOptions={filterOptions}
                                getOptionLabel={(option) =>
                                  option.fields_name || ""
                                }
                                onChange={(e, value) => {
                                  onChange(value ? value.field_setting_id : "");
                                }}
                                value={
                                  subCategoryOption?.find(
                                    (option) =>
                                      option.field_setting_id === value
                                  ) || null
                                }
                                sx={{ margin: "10px 0px" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Sub Category"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                          />
                        </FormControl>
                        {/* <FormControl fullWidth>
                          <InputLabel id="select-label">
                            Sub Category
                          </InputLabel>
                          <Controller
                            control={control}
                            name="subcategory"
                            // defaultValue={category}
                            render={({ field: { onChange, value } }) => (
                              <SelectBox
                                name="subcategory"
                                labelId="select-label"
                                variant="outlined"
                                id="simple-select"
                                value={value || ""}
                                onChange={onChange}
                                label="Sub Category"
                                {...register("subcategory")}
                              >
                                {subCategoryOption?.map((category) => (
                                  <MenuItem
                                    key={category.field_setting_id}
                                    value={category.field_setting_id}
                                  >
                                    {category.fields_name}
                                  </MenuItem>
                                ))}
                              </SelectBox>
                            )}
                          />
                        </FormControl> */}
                        <span className="error-message">
                          {errors["subcategory"]?.message}
                        </span>
                      </div>
                      <div className="field_wrapper">
                        <div className="field">
                          <TextField
                            name="description"
                            label="Description"
                            multiline
                            minRows={3}
                            maxRows={3}
                            value={
                              description && description !== null
                                ? description
                                : ""
                            }
                            onChange={(e) => setDescription(e.target.value)}
                            variant="outlined"
                            style={{ marginTop: "15px", marginBottom: "15px" }}
                            fullWidth
                          />
                        </div>
                      </div>
                      <ButtonUI
                        loading={submitLoading}
                        variant="contained"
                        buttonText="Submit"
                        handleSubmit={handleSubmit}
                        onFormSubmit={onEditDocumentFormSubmit}
                      />
                    </div>
                  )}
              </div>
              <div
                className={
                  isPdfDetailsContainerOpen
                    ? "pdf-details-container"
                    : "hide-pdf-details-container"
                }
              >
                <div className="pdf-details-header">
                  <div className="pdf-details-header-icon">
                    <IoDocumentText />
                  </div>
                  <div className="pdf-details-header-title">
                    Document Details
                  </div>
                  <div
                    className="close-pdf-details-container-icon"
                    onClick={changePdfDetailsContainerVisibility}
                  >
                    <RiCloseCircleFill />
                  </div>
                </div>
                <div className="pdf-details-content">
                  <PdfDetails
                    pdfData={pdfData}
                    documentID={documentID}
                    documentTitle={documentTitle}
                    documentType={documentType}
                    pdfDetailsVersionData={pdfDetailsVersionData}
                    documentVersionId={documentVersionId}
                    setDocumentVersionId={setDocumentVersionId}
                    document_version={document_version}
                  />
                </div>
                {/* <div className="pdf-details-content">
                  <PdfVersion documentID={documentID} />
                </div> */}
                {/* <div className="pdf-details-content">
                  <PdfHistory documentID={documentID} />
                </div> */}
              </div>
            </div>
          ) : !pdfDataLoading ? (
            <div className="show-pdf-editor">
              <div
                onClick={() => setEditor(!editor)}
                className="go-back-to-pdf-viewer"
                title="Back"
              >
                <IoArrowBackCircleSharp />
              </div>
              <div>
                <PdfEditor
                  pdfBase64={pdfBase64}
                  documentID={documentID}
                  documentTitle={documentTitle}
                  documentURL={documentURL}
                  setEditor={setEditor}
                />
              </div>
            </div>
          ) : (
            <Loading />
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default PdfViewer;

{
  /* <div className="pdf-viewer-tabs-header">
                  {pdfTab.map((tab, index) => (
                    <div
                      key={tab.id}
                      className={
                        value === tab.id
                          ? "pdf-viewer-active-tab-title pdf-viewer-tab-title"
                          : "pdf-viewer-tab-title"
                      }
                      onClick={() => handleChange(tab.id)}
                    >
                      <span>{tab.title}</span>
                    </div>
                  ))}
                </div>
                {pdfTab.map((tab, index) => {
                  return (
                    <div
                      key={tab.id}
                      hidden={value !== tab.id}
                      id={`simple-tabpanel-${tab.id}`}
                      aria-labelledby={`simple-tab-${tab.id}`}
                    >
                      <div className="doc-detail-tabs">
                        {tab.title !== "Details"
                          ? tab.content(documentID)
                          : tab.content(pdfData)}
                      </div>
                    </div>
                  );
                })} */
}
