const getSmsTemplates = async () => {
	try {
		const response = await fetch(
			`https://clguat.nablasol.net/rest/v11_20/get-message-templates`,
			{
				method: "GET",
				headers: {
					Accept: "application/json",
				},
			}
		);
		const data = await response.json();

		return data;
	} catch (error) {
		return error;
	}
};

export default getSmsTemplates;
