import React, { useState, useContext, useEffect, useCallback } from "react";
import { Upload } from "lucide-react";
import { useDropzone } from "react-dropzone";
import { nanoid } from "nanoid";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import addDocument from "../../api/addDocument";
import addAdditionalDocument from "../../api/addAdditionalDocument";
import "../../styles/AddDocument.css";
import baseUrl from "../../config/baseUrl";
import addDoc from "../../images/add-document.png";
import clientsData from "../../api/clients";
import useDebounceSearch from "../../hooks/useDebounceSearch";
import elasticToken from "../../config/elasticToken";
import getClientRefNo from "../../api/getClientRefNo";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
];

const addDocumentFormSchema = yup.object().shape({
  documentName: yup.string().required("Document Name is required field"),
  // documentDescription: yup
  //   .string()
  //   .required("Document Description is required field"),
  addClientName: yup
    .string()
    .nullable()
    .required("Client Name is required field"),
  // Category: yup.string().required("Category is required field"),
  subCategory: yup.string().required("Sub Category is required field"),
  addRequestName: yup.string(),
  // addDocumentStatus: yup.string().required("Status is required field"),
  fileUpload: yup
    .mixed()
    .test("required", "Please select a file", (value) => {
      return value && value.length > 0;
    })
    .test("type", "We only support images and pdf", (value) => {
      return value && SUPPORTED_FORMATS.includes(value[0]?.type);
    })
    .test(
      "fileSize",
      "Please upload file having size less than 5MB",
      (value) => {
        return value && value[0]?.size <= 5000000;
      }
    ),
});

const AddDocument = ({
  onAddDocumentButtonClick,
  setDocumentAddedToTrue,
  //categoryOptions,
  subCategoryOptions,
  //clientNames,
}) => {
  const [loading, setLoading] = useState(false);
  const [requestOptions, setRequestOptions] = useState([]);
  const { token, user_id, is_admin } = useContext(AuthContext);
  const [linkRequest, setLinkRequest] = useState(false);
  const [clientNames, setClientNames] = useState([]);
  const [searchClientNotFound, setSearchClientNotFound] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(true);
  const [openSubCategory, setOpenSubCategory] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const loadingSubCategory = openSubCategory && subCategoryOptions.length === 0;
  const [clientNameLabel, setClientNameLabel] = useState("");
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [showMoreLoading, setShowMoreLoading] = useState(false);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      setFileError(true);
    }

    const filteredFiles = acceptedFiles.filter((file) =>
      /\.(jpg|jpeg|gif|png|pdf)$/i.test(file.name)
    );

    const unsupportedFiles = acceptedFiles.filter(
      (file) => !/\.jpg$|\.jpeg$|\.gif$|\.png$|\.pdf/i.test(file.name)
    );

    if (unsupportedFiles.length > 0) {
      setFileError(true);
    } else {
      setFileError(false);
    }

    setFiles(filteredFiles);
    setValue("fileUpload", filteredFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: SUPPORTED_FORMATS.join(","),
  });

  const getClientList = useCallback(
    async (searchTerm, offsetValue, contactId) => {
      if (offsetValue === 0) {
        setClientNames([]);
      }
      if (searchTerm) {
        setClientNameLabel("Client Name");
        setSearchLoading(true);
        try {
          const url = `https://clguat.nablasol.net/rest/v11_20/customGlobalSearch?q=${searchTerm}&max_num=20&offset=${offsetValue}&highlights=true&module_list=Cases`;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${elasticToken}`,
            },
          });
          const data = await response.json();

          if (data?.total > 0) {
            setClientNames((prev) => [...prev, ...data.records]);
            setTotal(data.total);
            setSearchLoading(false);
            setShowMoreLoading(false);
          } else {
            setSearchLoading(false);
            setSearchClientNotFound(true);
          }
        } catch (error) {
          setSearchLoading(false);
          console.error("Error:", error);
        }

        // const url = `${baseUrl}/api/get-all-client-data?isAdmin=${is_admin}&userId=${user_id}&client_name=${searchTerm}`;
        // const res = await clientsData({ token, api: url });
        // if (res.code === 200) {
        //   setClientNames(res?.data[0]?.clients);
        // } else {
        //   setSearchClientNotFound(true);
        // }
      } else {
        setClientNameLabel("Client Name / Alien Number");
      }
    },
    [is_admin, user_id, token]
  );
  const [searchTerm, handleClientNameSearch] = useDebounceSearch(
    "",
    "",
    offset,
    "",
    2000,
    getClientList
  );

  // const statusOptions = [
  //   {
  //     name: "Active",
  //     value: "active",
  //   },
  //   {
  //     name: "Inactive",
  //     value: "inactive",
  //   },
  // ];

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.fields_name,
  });

  const filterOptionsLinkList = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.document_req_name,
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addDocumentFormSchema),
  });

  const selectedClient = watch("addClientName");

  const onAddDocumentFormSubmit = async (addDocumentFormData) => {
    setLoading(true);

    if (
      addDocumentFormData.addRequestName &&
      addDocumentFormData.addRequestName != "Additional Document"
    ) {
      const addDocumentFormResponse = await addDocument({
        token: token,
        user_id: user_id,
        contact_id: addDocumentFormData.addClientName,
        document_name: addDocumentFormData.documentName,
        document_desc: addDocumentFormData.documentDescription,
        document_request_id: addDocumentFormData.addRequestName,
        category_id: "0c73a993-4dce-4430-b5a9-9d4ea57739e8",
        document_sub_category: addDocumentFormData.subCategory,
        favourite: "0",
        // document_status: addDocumentFormData.addDocumentStatus,
        document_url: files[0],
      });

      if (addDocumentFormResponse?.code === 200) {
        setLoading(false);
        onAddDocumentButtonClick();
        setDocumentAddedToTrue();
        toast.success("Document is added successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setLoading(false);
        toast.error("Error while adding document", {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      const addAdditionalDocumentFormResponse = await addAdditionalDocument({
        token: token,
        user_id: user_id,
        contact_id: addDocumentFormData.addClientName,
        document_name: addDocumentFormData.documentName,
        document_desc: addDocumentFormData.documentDescription,
        category_id: "0c73a993-4dce-4430-b5a9-9d4ea57739e8",
        document_sub_category: addDocumentFormData.subCategory,
        // document_status: addDocumentFormData.addDocumentStatus,
        content_type: "pdf",
        document_url: files[0],
      });

      if (addAdditionalDocumentFormResponse?.code === 200) {
        // const url = `https://filescour.consumerlaw.com/upload`;
        // const token = "29649f8f-4055-4b7e-b5db-c3e57d875d66";

        // const formData = new FormData();
        // formData.append("file", addDocumentFormData.fileUpload[0]);
        // const response = await fetch(url, {
        //   method: "POST",
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        //   body: formData,
        // });

        setLoading(false);
        onAddDocumentButtonClick();
        setDocumentAddedToTrue();
        toast.success("Document is added successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setLoading(false);
        toast.error("Error while adding document", {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const fetchRequestList = async () => {
    setLinkRequest(false);
    //const url = `${baseUrl}/api/get-doc-request-data?userId=1&isAdmin=1&contact_id=${selectedClient}&unlinked=True`;
    const url = `${baseUrl}/api/get-client-document-requests-for-linking?userId=1&isAdmin=1&contact_id=${selectedClient}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (data?.code === 200) {
        // Assuming data?.data?.document_requests_for_linking is the list of request options
        const documentCases = data?.data?.document_requests_for_linking || [];

        // Add "Additional Document" as the first item in the list
        const updatedOptions = [
          { document_request_id: "", document_req_name: "Additional Document" },
          ...documentCases,
        ];

        setRequestOptions(updatedOptions);
      } else {
        setRequestOptions([
          {
            document_request_id: "",
            document_req_name: "Additional Document",
          },
        ]);
      }
    } catch (error) {
      return error;
    }
  };

  const handleRequestListLink = () => {
    if (selectedClient && requestOptions?.length > 0) {
      setLinkRequest(!linkRequest);
    }
    if (linkRequest) {
      setValue("addRequestName", "");
    }
  };

  const handleClientNameChange = async (contactId) => {
    const clientRefNoResponse = await getClientRefNo({
      contact_id: contactId,
      token: token,
    });
    //setClientRefNo(clientRefNoResponse?.data[0]?.client_reference_number);

    if (clientRefNoResponse.code !== 200) {
      toast.error(
        "Error while getting client details in DMS. Please contact admin!",
        {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      //setClientNames([]);
      setValue("addClientName", "");
    } else {
      setClientNameLabel("Client Name");
      setValue("addRequestName", "");
      fetchRequestList();
    }
  };

  useEffect(() => {
    if (
      typeof selectedClient !== "undefined" &&
      selectedClient !== null &&
      selectedClient
    ) {
      handleClientNameChange(selectedClient);
    }
  }, [selectedClient]);

  useEffect(() => {
    setOffset(0);
    setTotal(0);
  }, [searchedTerm]);

  return (
    <div className="add-document-page">
      <div className="add-document-container">
        <div className="add-document-header">
          <div className="add-document-title-with-img">
            <img src={addDoc} height="100px" width="120px" alt="Add Document" />
            <div className="add-document-title">Add New Document</div>
          </div>

          <ButtonUI
            formButton={false}
            variant="contained"
            buttonText={<AiFillCloseCircle />}
            style={{
              background: "none",
              padding: "0px",
              minWidth: "auto",
            }}
            onClickFunc={onAddDocumentButtonClick}
          ></ButtonUI>
        </div>
        <div className="add-document-form">
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message">
              <Controller
                control={control}
                name="addClientName"
                render={({ field }) => (
                  <Autocomplete
                    className="clientNameAuto"
                    name="addClientName"
                    sx={{ margin: "10px 0px" }}
                    options={[
                      ...clientNames,
                      ...(total > offset + 20 || showMoreLoading
                        ? [{ name: "Show More", id: "show_more" }]
                        : []),
                    ]}
                    loading={searchLoading}
                    //getOptionLabel={(option) => option.name}
                    getOptionLabel={(option) => {
                      // Check if option is an object and has a valid 'name' property
                      if (
                        typeof option === "object" &&
                        option !== null &&
                        typeof option.name === "string"
                      ) {
                        if (option.id === "show_more") return option.name;
                        return option.name || "";
                      }

                      // Return a fallback value (could be an empty string or some placeholder text)
                      return "";
                    }}
                    noOptionsText={
                      !searchClientNotFound
                        ? "Please enter the client name / alien number"
                        : "No clients found"
                    }
                    clearOnBlur={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          !clientNameLabel
                            ? "Client Name / Alien Number"
                            : clientNameLabel
                        }
                        InputProps={{
                          ...params.InputProps,
                        }}
                        onChange={(event) => {
                          if (!event.target.value) {
                            setClientNameLabel("Client Name / Alien Number");
                          }
                          const searchTerm = event.target.value;
                          handleClientNameSearch(searchTerm);
                          setSearchedTerm(searchTerm);
                        }}
                        onBlur={() => {
                          if (
                            !selectedClient &&
                            typeof selectedClient != "undefined"
                          ) {
                            setClientNameLabel("Client Name / Alien Number");
                          }
                        }}
                      />
                    )}
                    filterOptions={(options, state) => options}
                    renderOption={(props, option) => {
                      if (option.id === "show_more") {
                        return (
                          <li
                            {...props}
                            onClick={async () => {
                              setShowMoreLoading(true); // Set loading to true
                              setOffset((prev) => prev + 20);
                            }}
                            style={{
                              cursor: "pointer",
                              color: showMoreLoading ? "grey" : "#007bff",
                            }}
                          >
                            {showMoreLoading ? "Loading..." : option.name}
                          </li>
                        );
                      }
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                          <br />
                          Alien Number: {option.im_alien_number_c}
                        </li>
                      );
                    }}
                    {...field}
                    value={
                      clientNames
                        ? clientNames.find(
                            (clientName) => clientName.id === field.value
                          ) || null
                        : null
                    }
                    onChange={(event, newValue) => {
                      if (newValue === null || newValue === undefined) {
                        field.onChange(null);
                      } else if (newValue.id !== "show_more") {
                        const selectedClient = clientNames?.find(
                          (clientName) => clientName.name === newValue?.name
                        );
                        if (selectedClient) {
                          field.onChange(selectedClient.id);
                        }
                      }
                    }}
                    classes={{ paper: "autocomplete-paper" }}
                  />
                )}
              />

              {/* <FormControl fullWidth>
                <InputLabel id="client-select">Client Name</InputLabel>
                <Controller
                  control={control}
                  name="addClientName"
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      name="addClientName"
                      sx={{ margin: "10px 0px" }}
                      labelId="client-select"
                      variant="outlined"
                      id="client-select"
                      label="Client Name"
                      {...field}
                      {...register("addClientName")}
                    >
                      {clientNames?.map((clientName) => (
                        <MenuItem
                          key={clientName.contact_id}
                          value={clientName.contact_id}
                        >
                          {clientName.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl> */}
              <span className="add-doc-error-message">
                {errors["addClientName"]?.message}
              </span>
            </div>
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="documentName"
                label="Document Name"
                type="text"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              <span className="add-doc-error-message">
                {errors["documentName"]?.message}
              </span>
            </div>
          </div>
          <div className="add-document-inputs">
            <div
              className="add-document-inputs-with-error-message"
              style={{ width: "100%" }}
            >
              <InputUI
                name="documentDescription"
                label="Document Description"
                type="text"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              {/* <span className="add-doc-error-message">
                {errors["documentDescription"]?.message}
              </span> */}
            </div>
          </div>
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="Category"
                label="Category"
                type="text"
                variant="outlined"
                disabled
                value="Manual Process"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              {/* <FormControl fullWidth>
                <InputLabel id="category-select">Category</InputLabel>
                <Controller
                  control={control}
                  name="Category"
                  defaultValue="0c73a993-4dce-4430-b5a9-9d4ea57739e8"
                  render={({ field }) => (
                    <Select
                      name="Category"
                      sx={{ margin: "10px 0px" }}
                      labelId="category-select"
                      variant="outlined"
                      id="category-select"
                      label="Category"
                      disabled
                      {...field}
                      {...register("Category")}
                    >
                      {categoryOptions?.map((category) => (
                        <MenuItem
                          key={category.fields_name}
                          value={category.field_setting_id}
                        >
                          {category.fields_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="add-doc-error-message">
                {errors["Category"]?.message}
              </span> */}
            </div>
            {/* <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Status</InputLabel>
                <Controller
                  control={control}
                  name="addDocumentStatus"
                  defaultValue="active"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="addDocumentStatus"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Status"
                      {...register("addDocumentStatus")}
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="add-doc-error-message">
                {errors["addClientStatus"]?.message}
              </span>
            </div> */}
            <div className="add-document-inputs-with-error-message">
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="subCategory"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Autocomplete
                      open={openSubCategory}
                      onOpen={() => setOpenSubCategory(true)}
                      onClose={() => setOpenSubCategory(false)}
                      id="autocomplete-client-category"
                      options={subCategoryOptions || []}
                      filterOptions={filterOptions}
                      loading={loadingSubCategory}
                      getOptionLabel={(option) => option.fields_name || ""}
                      sx={{ margin: "10px 0px" }}
                      // value={
                      //   subCategoryOptions.find(
                      //     (option) => option.field_setting_id === value
                      //   ) || null
                      // }
                      onChange={(event, newValue) => {
                        // Pass the selected value to onChange to update react-hook-form state
                        onChange(newValue ? newValue.field_setting_id : "");
                      }}
                      // onBlur={onBlur}
                      // isOptionEqualToValue={(option, value) =>
                      //   option.field_setting_id === value
                      // }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub Category"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingSubCategory ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
              {/* <FormControl fullWidth>
                <InputLabel id="category-select">Sub Category</InputLabel>
                <Controller
                  control={control}
                  name="subCategory"
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      name="subCategory"
                      sx={{ margin: "10px 0px" }}
                      labelId="category-select"
                      variant="outlined"
                      id="category-select"
                      label="Sub Category"
                      {...field}
                      {...register("subCategory")}
                    >
                      {subCategoryOptions?.map((category) => (
                        <MenuItem
                          key={category.field_setting_id}
                          value={category.field_setting_id}
                        >
                          {category.fields_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl> */}
              <span className="add-doc-error-message">
                {errors["subCategory"]?.message}
              </span>
            </div>
          </div>
          <div className="add-document-inputs">
            <div
              className="add-document-inputs-with-error-message upload-ui"
              style={{ width: "100%" }}
            >
              {/* <InputUI
                name="fileUpload"
                type="file"
                variant="outlined"
                cssStyles={{
                  margin: "10px 0px",
                }}
                register={register}
              /> */}
              <div
                {...getRootProps({ className: "dropzone" })}
                className="border-dashed border-2 border-gray-400 p-4 w-full text-center cursor-pointer h-[200px] rounded-md flex flex-col items-center justify-center"
              >
                <input
                  name="fileUpload"
                  {...getInputProps()}
                  {...register("fileUpload")}
                />
                <div className="flex flex-col justify-center items-center">
                  <Upload />
                  <p className="font-bold text-muted-foreground text-lg">
                    Drop a file here
                  </p>
                  {files?.length == 1 && (
                    <p className="text-sm text-gray-600 mt-2">
                      {files[0].name}
                    </p>
                  )}
                </div>
              </div>
              {fileError && (
                <span className="add-doc-error-message">
                  {errors["fileUpload"]?.message}
                </span>
              )}
            </div>
          </div>
          <div className="add-document-inputs">
            {!linkRequest && requestOptions?.length > 0 ? (
              <>
                <div className="add-document-inputs-with-error-message select-ui">
                  <span
                    className="request-list-link"
                    onClick={handleRequestListLink}
                  >
                    Link to Request List
                  </span>
                </div>
              </>
            ) : null}
            {linkRequest ? (
              <>
                <div className="add-document-inputs-with-error-message">
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="addRequestName"
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          options={requestOptions}
                          getOptionLabel={(option) =>
                            option.document_req_name || ""
                          }
                          filterOptions={filterOptionsLinkList}
                          onChange={(e, value) => {
                            onChange(value ? value.document_request_id : "");
                          }}
                          value={
                            requestOptions?.find(
                              (option) => option.document_request_id === value
                            ) || null
                          }
                          sx={{ margin: "10px 0px" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Request List"
                              variant="outlined"
                              sx={{ margin: "10px 0px" }}
                            />
                          )}
                        />
                      )}
                    />

                    {/* <Controller
                      control={control}
                      name="addRequestName"
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          name="addRequestName"
                          sx={{ margin: "10px 0px" }}
                          labelId="requestName"
                          variant="outlined"
                          id="request-select"
                          label="Request List"
                          {...field}
                          {...register("addRequestName")}
                        >
                          <MenuItem key="All" value="Select Request List">
                            Select Request List
                          </MenuItem>
                          {requestOptions.map((option) => (
                            <MenuItem
                              key={option.document_request_id}
                              value={option.document_request_id}
                            >
                              {option.document_req_name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    /> */}
                  </FormControl>
                </div>
              </>
            ) : null}
          </div>
          <div className="add-document-button">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Add Document"
              style={{
                width: "150px",
                height: "35px",
                backgroundColor: "#007bff",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
                marginTop: "20px",
              }}
              handleSubmit={handleSubmit}
              onFormSubmit={onAddDocumentFormSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDocument;
