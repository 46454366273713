const getTemplateDescription = async ({ cm_name, template_id, client_id }) => {
	console.log(cm_name);
	try {
		const response = await fetch(
			`https://clguat.nablasol.net/rest/v11_20/get-message-templates-descriptions`,
			{
				method: "POST",
				body: JSON.stringify({
					cm_name,
					sms_template_id: template_id,
					cm_id: client_id,
				}),
			}
		);

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}

		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Error fetching template description:", error);
		return error;
	}
};

export default getTemplateDescription;
